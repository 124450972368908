<template>
  <div class="debit-card flex flex-col">
    <div class="card__header flex justify-between card-header px-5">
      <div class="flex flex-col">
        <h4 class="m-0 card__title">{{ bank.displayName }}</h4>
        <p>{{ bank.bankAccountId }}</p>
      </div>
      <edit-2-icon class="edit-card w-5" @click="handleEdit"></edit-2-icon>
    </div>
    <div class="card__body px-5">
      <div class="card__content">
        <span v-if="bank.isUsedForBilling === true && bank.isUsedForSettlement === false">Account is used for billing.</span>
        <span v-if="bank.isUsedForBilling === false && bank.isUsedForSettlement === true">Account is used for settlement.</span>
        <span v-if="bank.isUsedForBilling === true && bank.isUsedForSettlement === true">Account is used for settlement & billing.</span>
      </div>
      <div class="card__content mt-4">
        <span>Account Name *</span>
        <span>{{ bank.accountName }}</span>
      </div>
      <div class="flex justify-between w-full my-5">
        <div class="card__content">
          <span>BSB</span>
          <span>{{ maskBsb(bank.bsb) }}</span>
        </div>
        <div class="card__content">
          <span>Account Number</span>
          <span>{{ bank.accountNumber }}</span>
        </div>
      </div>
      <vs-button v-round class="w-full my-5" v-if="!bank.tillConnectorDetails && paymentOptions.payNow && bank.isUsedForSettlement" @click="addTill">Configure till connector</vs-button>
      <vs-button v-round class="w-full only-border-btn my-5" v-if="bank.tillConnectorDetails && paymentOptions.payNow && bank.isUsedForSettlement" @click="editTill">Edit till connector</vs-button>
    </div>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";

export default {
  name: "DebitCardComponent",
  components: {
    Edit2Icon,
  },

  data() {
    return {
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
    };
  },

  props: {
    bank: {
      type: Object,
      required: true,
    },
    paymentOptions: {
      type: Object,
      required: true,
    },
  },

  computed: {
    icon() {
      return this.uploadedUrl + this.card.icon;
    },
  },

  methods: {
    handleEdit() {
      this.$emit("edit");
    },

    addTill() {
      this.$emit("addTill");
    },

    editTill() {
      this.$emit("editTill");
    },

    maskBsb(string){
      let arr= string.replace(" ", "").split("");
      arr.splice(3, 0, "-");
      string = arr.join("");
      return string;
    }
  },
};
</script>