<template>
  <div id="profile-page">
    <vs-row>
      <vs-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="paymentOptions" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">
                Merchant details <span class="mid-blue">*</span>
              </h3>
            </div>
            <vs-row>
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <div>
                  <label class="w-full">Business name *</label>
                  <vs-input v-model="companyName" v-validate="'required'" class="w-full" name="companyName" placeholder="Enter business name" size="large"/>
                  <span v-show="errors.has('companyName')" class="text-danger text-sm">{{ errors.first("companyName") }}</span>
                </div>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vs-card>
    </vs-row>

    <!-- Payment Options -->
    <vs-row>
      <vs-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="paymentOptions" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">
                Payment Options <span class="mid-blue">*</span>
              </h3>
            </div>
            <p>Select the payment options you’d like to enable for this merchant.</p>
            <vs-row class="mt-8">
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <vs-checkbox v-model="paynow">Pay Now</vs-checkbox>
              </vs-col>
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <vs-checkbox v-model="paylater">Pay Later</vs-checkbox>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vs-card>
    </vs-row>

    <!-- Paynow payment methods -->
    <vs-row>
      <vs-card class="accordin-card mt-5" v-if="paynow">
        <vs-collapse accordion>
          <vs-collapse-item ref="payNowPaymentMethods" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">
                Pay Now payment methods <span class="mid-blue">*</span>
              </h3>
            </div>
            <span
              >Select the Pay Now payment methods you’d like to offer this
              merchant.</span
            >
            <vs-row>
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <vs-row class="m-3 mb-10">
                  <vs-select placeholder="Select payment methods" multiple autocomplete class="selectExample" v-model="selectedCards" @change="checkSelected">
                    <div class="selectAllDiv">
                      <a class="dropdownSelectAllButton" @click="selectAllCards">{{!allCardsSelected ? `Select all` : `Unselect all`}}</a>
                    </div>

                    <vs-select-item :key="index" :value="card" :text="card.title" v-for="(card, index) in cardsList"/>
                  </vs-select>
                </vs-row>
              </vs-col>
            </vs-row>
            <!-- groups card -->

            <div class="grid grid-cols-3 gap-5">
              <div class="debit-card flex flex-col" v-for="(card, indextr) in selectedCards" :key="indextr">
                <div class="card__header flex justify-between card-header p-2">
                  <div class="flex w-10/12 items-center">
                    <S3ImageGenerator :document="card.icon" :is-base64="false" :custom-class="'card-image'" :key="card.icon"/>
                    <h4 class="m-0 card__title ml-4">{{ card.title }}</h4>
                  </div>
                  <a @click="closeCard(indextr)">
                    <x-icon size="1.5x" class="custom-class"></x-icon
                  ></a>
                </div>
                <div class="card__body">
                  <div class="card__content p-4">
                    <span class="flex mb-1">MSF <span class="mid-blue">*</span></span>
                    <vs-select placeholder="Select MSF rate" class="selectExample" v-model="card.msf" @change="checkMsf(indextr)">
                      <vs-select-item :key="index" :value="msfRate.msf" :text="msfRate.displayMsf" v-for="(msfRate, index) in msfRates" />
                    </vs-select>
                    <span class="text-danger text-sm" v-show="errors.has(`msf${indextr}`)">{{ errors.first(`msf${indextr}`) }}</span>
                  </div>
                  <div class="card__content p-4 mb-5">
                    <span>Surcharge to customer </span>
                    <money id="surcharge2" v-bind="money2" v-model.lazy="card.surcharge" :name="`surcharge${indextr}`" data-vv-as="surcharge"
                      v-validate="`required|decimal|max_value:${card.msf}|min_value:0`" @keydown.native="$event.key === '-' ? $event.preventDefault() : null"></money>
                    <span class="text-danger text-sm" v-show="errors.has(`surcharge${indextr}`)">{{ errors.first(`surcharge${indextr}`) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </vs-card>


      <!-- This is for Bank accounts part -->
      <vs-card class="accordin-card mt-5 legals" v-if="paynow || paylater">
        <vs-collapse accordion>
          <vs-collapse-item open ref="bankCollapse">
            <div slot="header">
              <h4 class="font-normal text-lg mb-0">Bank Accounts</h4>
            </div>
            <div>
              <p class="mb-5">Add the merchant’s bank accounts for funds to settle into.</p>

              <div class="legals__item">
                <div class="legals__item--header" :class="{'flex-row justify-end items-center':bankAccountList.length > 0,}">
                  <a class="reset-button circular--btn flex" @click="addBankAccountPopup"><plus-icon size="1.5x" class="button-icon mr-2"></plus-icon>Add
                    Account</a>
                </div>
              </div>

              <div class="grid grid-cols-3 gap-5">
                <bank-details-card v-for="(bank, index) in bankAccountList" :bank="bank" :paymentOptions="paymentOptions" :key="index" @edit="handleEditPopup(bank, index)"
                  @addTill="configureTillConnectorPopup(bank, index)" @editTill="editTillConnectorPopup(bank, index)">
                </bank-details-card>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </vs-card>
    </vs-row>

    <!-- paylater products starts here -->

    <vs-row v-if="paylater">
      <vs-card class="accordin-card">
        <vs-collapse accordion>
          <vs-collapse-item ref="paylaterProducts" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">Pay Later products <span class="mid-blue">*</span></h3>
            </div>
            <p class="mb-4">Enable the Pay Later products available to this merchants.</p>
            <!-- dropdown -->
            <vs-row>
              <vs-col class="pr-6" vs-w="4" vs-xs="12">
                <vs-row class="m-3 mb-10">
                  <vs-select placeholder="Select products" multiple autocomplete class="selectExample" v-model="selectedProducts" @change="checkSelectedProducts">
                    <div class="selectAllDiv">
                      <a class="dropdownSelectAllButton" @click="selectAllProducts">{{!allProductsSelected ? `Select all` : `Unselect all`}}</a>
                    </div>

                    <vs-select-item :key="index" :value="paylaterProduct" :text="paylaterProduct.productName" v-for="(paylaterProduct, index) in paylaterProductsList"/>
                  </vs-select>
                </vs-row>
              </vs-col>
            </vs-row>
            <vs-row>
              <div class="plans-grid-paylater w-full">
                <div v-for="(plan, index) in selectedProducts" :key="index" class="plans-item">
                  <div class="plan-items__header w-full flex justify-between">
                    <div>
                      <h4>{{ plan.productName }}</h4>
                      <p class="py-2">{{plan.feeModel == "MSF_INCLUDED"? `MSF to merchant (MSF-M)`: `MSF to customer (MSF-C)`}}</p>
                      <p>Plan ID:&nbsp;{{plan.customPlanId ? plan.customPlanId : plan.planId}}</p>
                    </div>
                    <x-icon class="plan-item__close cursor-pointer" size="14" @click="closeProduct(index)"></x-icon>
                  </div>
                  <div class="plan-items-content">
                    <label class="w-full" for="displayNames">Display name</label>
                    <vs-input v-model="plan.displayName" class="w-full mb-2" name="displayNames" placeholder="Enter display name"/>
                    <label class="w-full" for="msfEdit">MSF</label>
                     <money id="msfEdit" v-bind="money2" v-model.lazy="plan.msf" :name="`msf[${index}]`" data-vv-as="msf" v-validate="`required|decimal`"
                        placeholder="Enter MSF" class="w-full mb-5" @keydown.native="$event.key === '-' ? $event.preventDefault() : null"></money>
                    <vs-button v-round class="w-full only-border-btn medium" @click="openPlanConfigurationPopup(plan, index)">View Plan Configuration</vs-button>
                  </div>
                </div>
              </div>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vs-card>
    </vs-row>
    <!-- paylater product ends here -->

    <vs-row v-if="isOnboarding">
      <vs-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="paymentOptions" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">
                Applications
              </h3>
            </div>
            <div class="grid lg:grid-cols-3 gap-5">
              <div class="debit-card flex flex-col">
                <div class="card__header flex justify-between card-header px-5 mb-0">
                  <div class="flex flex-col">
                    <h4 class="m-0 card__title">Onboarding application</h4>
                  </div>
                </div>
                <div class="card__body px-5 pb-8">

                  <div class="card__content">
                    <p class="mt-4 mb-6 font-light">Submission date: <strong class="font-light text-dark-blue">{{formatDate(onboardingAgreementDate)}}</strong></p>
                  </div>
                  <vs-button
                    v-round
                    class="w-full only-border-btn medium"
                    @click="goToOnboarding"
                    >View application</vs-button
                  >
                </div>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </vs-card>
    </vs-row>

    <!-- New/ edit merchant > Send account setup link to -->
      <vs-row v-if="!isUiSetupComplete">
      <vs-card class="mt-5 bg-gray">

            <div  class="px-8 py-4">
              <h3 class="text-left card-title font-normal">Send account setup link to</h3>
            </div>
            <vs-row>
              <vs-col class="px-8 py-6">
                 <vs-row>
                    <vs-col class="pr-6" vs-w="4" vs-xs="12">
                      <div>
                        <label class="w-full">Contact name *</label>
                        <vs-input v-model="setupLinkData.contactName" v-validate="'required'" class="w-full" name="setupLinkDatacontactName"
                          placeholder="Enter contact name" size="large"/>
                        <span v-show="errors.has('setupLinkDatacontactName')" class="text-danger text-sm">
                          {{  changeMessage(errors.first("setupLinkDatacontactName"), "setupLinkDatacontactName", 'contact name')  }}
                        </span>
                      </div>
                    </vs-col>
                    <vs-col vs-w="4" vs-xs="12">
                      <div>
                        <label class="w-full">Email Address *</label>
                        <vs-input v-model="setupLinkData.emailAddress" v-validate="'required|email'" class="w-full" name="setupLinkDataemailAddress"
                          placeholder="Enter email address" size="large"/>
                        <span v-show="errors.has('setupLinkDataemailAddress')" class="text-danger text-sm">
                          {{  changeMessage(errors.first("setupLinkDataemailAddress"), "setupLinkDataemailAddress", 'email address')  }}
                        </span>
                      </div>
                    </vs-col>
                    <vs-col vs-w="4" vs-xs="12" v-if="accountStatus == 'Active'">
                      <div class="input-container">
                        <vs-button v-round class="sendCred only-border-btn" @click="resendMerchantSetupLink">
                          <span class="material-icons-outlined mr-2 send"> send </span>Re-send link
                        </vs-button>
                      </div>
                    </vs-col>
                  </vs-row>
              </vs-col>
            </vs-row>

      </vs-card>
    </vs-row>
    <!-- New/ edit merchant > Send account setup link to -->
    <vs-row>
      <vs-card class="accordin-card mt-5">
        <vs-collapse accordion>
          <vs-collapse-item ref="contactDetail" :open="true">
            <div slot="header">
              <h3 class="text-left card-title font-normal">Contact details<span class="mid-blue">*</span></h3>
            </div>
            <vs-row>
              <div class="px-8 mt-6 w-full pb-8">
                <vs-row>
                  <vs-col vs-w="4" class="textBlack">Communication type</vs-col>
                  <vs-col vs-w="4" class="textBlack">Email address</vs-col>
                  <vs-col vs-w="4" class="textBlack">Phone number</vs-col>
                </vs-row>

                <hr class="line-hr-thin mt-2 mb-2">
                <vs-row v-for="(contact, index) in contactDetails" :key="index" class="mt-8">
                  <vs-col vs-w="4"><p>{{ contact.name }}</p></vs-col>
                  <vs-col vs-w="4">
                    <vs-input
                      :name="`email[${index}]`"
                      v-model="contact.email"
                      class="w-full"
                      data-vv-as="email address"
                      v-validate="'required|email'"
                    />
                    <span v-show="errors.has(`email[${index}]`)" class="text-danger text-sm">{{ errors.first(`email[${index}]`) }}</span>
                  </vs-col>
                  <vs-col vs-w="4">
                    <vs-input
                      :name="`phone[${index}]`"
                      v-model="contact.phoneNumber"
                      v-mask="numberMask[index].mask"
                      class="w-full"
                      data-vv-as="phone number"
                      v-validate="'required|phoneFormat'"
                      v-on:input="checkValidPhoneNumber(index)"
                    />
                    <span v-show="errors.has(`phone[${index}]`)" class="text-danger text-sm">{{ errors.first(`phone[${index}]`) }}</span>
                  </vs-col>
                </vs-row>
              </div>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vs-card>
    </vs-row>
    <vs-popup v-show="addBankAccountPopupActive" class="add-bank-popup" :active.sync="addBankAccountPopupActive" @close="addBankPopupActive = false"
      :title="bankPopupTitle">
      <vs-row>
        <vs-col vs-w="12" class="my-4">
          <label>Account Name <span class="required-text">*</span></label>
          <vs-input class="w-full" v-validate="'required'" data-vv-validate-on="blur" data-vv-as="account name" data-vv-scope="addBank" v-model="accountName"
            placeholder="Enter account name" name="accountName"/>
          <span class="text-danger text-sm" v-show="errors.has('addBank.accountName')">{{ errors.first("addBank.accountName") }}</span>
        </vs-col>

        <div class="grid grid-cols-2 gap-x-10 my-6">
          <div>
            <label class="w-full text-xm font-normal block">BSB <span class="required-text">*</span></label>

            <input v-mask="'###-###'" class="vs-inputx vs-input--input normal w-11/12" v-model="bsb" placeholder="Enter BSB" data-vv-scope="addBank" name="bsb" data-vv-validate-on="keyup" @keyup="handleBsb">

            <span class="text-danger text-sm block py-3" v-show="errors.has('addBank.bsb')">{{ errors.first("addBank.bsb") }}</span>
          </div>
          <div>
            <label class="w-full text-xm font-normal block">Account Number <span class="required-text">*</span></label>

            <vs-input class="w-11/12" v-validate="'required'" data-vv-scope="addBank" v-model="accountNumber" type="number" placeholder="Enter account number"
              data-vv-validate-on="blur" data-vv-as="account number" name="accountNumber"></vs-input>

            <span class="text-danger text-sm block py-3" v-show="errors.has('addBank.accountNumber')">{{ errors.first("addBank.accountNumber") }}</span>
          </div>
        </div>

        <vs-col vs-w="8">
          <div>
            <label class="w-full text-xm font-normal">Display Name <span class="required-text">*</span></label>
            <vs-input class="w-8/12" v-validate="'required|max:40'" v-model="displayName" data-vv-scope="addBank" data-vv-validate-on="blur"
              placeholder="Enter display name" data-vv-as="display name" name="displayName"/>
            <span class="text-danger text-sm block py-3" v-show="errors.has('addBank.displayName')">{{ errors.first("addBank.displayName") }}</span>
          </div>
        </vs-col>

        <vs-col>
          <vs-row class="pr-6 mt-4" vs-w="8" vs-xs="12">
            <vs-checkbox v-model="isUsedForSettlement">This account will be used for settlement.</vs-checkbox>
          </vs-row>
          <vs-row class="pr-6 mt-4" vs-w="8" vs-xs="12">
            <vs-checkbox v-model="isUsedForBilling">This account will be used for billing.</vs-checkbox>
          </vs-row>
        </vs-col>

        <vs-col class="flex justify-end lg:mt-5 sm:mt-4">
          <vs-button class="text-xs mx-4 only-border-btn" v-round color="primary" @click="deleteBank" v-if="this.isUpdate && !(isUsedInPRT || isUsedInPR)">Delete</vs-button>
          <vs-button class="text-xs mx-4" v-round :disabled="!accountName || !bsb || !accountNumber || !displayName" color="primary" @click="addBankAccount"
            v-if="!this.isUpdate">Add Account</vs-button>
          <vs-button class="text-xs mx-4" v-round :disabled="!accountName || !bsb || !accountNumber || !displayName" color="primary" @click="updateBankAccount"
            v-if="this.isUpdate">Update Account</vs-button>
          <a class="text-xs flex items-center underline mid-blue" @click="closeModal">Cancel</a>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup class="configure-till-connector-popup" :active.sync="configureTillConnectorPopupActive" @close="closeTillConnectorModal" :title="currentBankName">
      <p class="block text-sm mb-5 text-grey">Configure Till connector for this account</p>
      <div>
        <label for="connectorName" class="w-full text-xm font-normal">Connector name <span class="required-text">*</span></label>
        <vs-input
          v-model="tillName"
          data-vv-validate-on="blur"
          data-vv-as="connector name"
          v-validate="'required|max:100'"
          data-vv-scope="addTill"
          name="connectorName"
          id="connectorName"
          class="w-8/12"
        />
        <span
          class="text-danger text-sm py-3 block"
          v-show="errors.has('addTill.connectorName')"
          >{{ errors.first("addTill.connectorName") }}</span
        >
      </div>

      <p class="block mt-2 mb-2">Till API credentials</p>
      <vs-divider></vs-divider>

      <div class="grid grid-cols-2 gap-x-5 gap-y-8 my-5">
        <div>
          <label for="integrationKey" class="w-full text-xm font-normal">Username <span class="required-text">*</span></label>
          <vs-input
            v-model="flipUsername"
            data-vv-validate-on="blur"
            data-vv-as="till username"
            v-validate="'required|max:100'"
            name="username"
            id="username"
            class="w-8/12"
            data-vv-scope="addTill"
          />

          <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.username')">{{ errors.first("addTill.username") }}</span>
        </div>

        <div>
          <label for="apiKey" class="w-full text-xm font-normal">Password <span class="required-text">*</span></label>
          <vs-input
            v-model="flipPassword"
            data-vv-validate-on="blur"
            data-vv-as="till password"
            data-vv-scope="addTill"
            v-validate="'required|max:100'"
            name="password"
            id="password"
            class="w-8/12"
          />
          <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.password')">{{ errors.first("addTill.password") }}</span>
        </div>
      </div>

      <p class="block mt-2 mb-2">Connector</p>
      <vs-divider></vs-divider>

      <div class="grid grid-cols-2 gap-x-5 gap-y-8 my-5">
        <div>
          <label for="integrationKey" class="w-full text-xm font-normal">Public integration key <span class="required-text">*</span></label>
          <vs-input
            v-model="publicIntegration"
            data-vv-validate-on="blur"
            data-vv-as="itegration key"
            v-validate="'required|max:100'"
            name="integrationKey"
            id="integrationKey"
            class="w-8/12"
            data-vv-scope="addTill"
          />

          <span class="text-danger text-sm py-3 block" v-show="errors.has('addTill.integrationKey')">{{ errors.first("addTill.integrationKey") }}</span>
        </div>

        <div>
          <label for="apiKey" class="w-full text-xm font-normal"
            >API key <span class="required-text">*</span></label
          >
          <vs-input
            v-model="apiKey"
            data-vv-validate-on="blur"
            data-vv-as="apikey"
            data-vv-scope="addTill"
            v-validate="'required|max:100'"
            name="apiKey"
            id="apiKey"
            class="w-8/12"
          />
          <span
            class="text-danger text-sm py-3 block"
            v-show="errors.has('addTill.apiKey')"
            >{{ errors.first("addTill.apiKey") }}</span
          >
        </div>
        <div>
          <label for="sharedSecret" class="w-full text-xm font-normal"
            >Shared secret <span class="required-text">*</span></label
          >
          <vs-input
            v-model="sharedSecret"
            data-vv-validate-on="blur"
            data-vv-scope="addTill"
            data-vv-as="shared secret"
            v-validate="'required|max:100'"
            name="sharedSecret"
            id="sharedSecret"
            class="w-8/12"
          />

          <span
            class="text-danger text-sm py-3 block"
            v-show="errors.has('addTill.sharedSecret')"
            >{{ errors.first("addTill.sharedSecret") }}</span
          >
        </div>
      </div>
      <vs-col class="flex justify-end lg:mt-5 sm:mt-4">
        <vs-button
          class="text-xs mx-4"
          v-round
          color="primary"
          @click="configureTillConnector"
          v-if="!this.isUpdate"
          >Save</vs-button
        >
        <vs-button
          class="text-xs mx-4"
          v-round
          color="primary"
          @click="updateTillConnector"
          v-if="this.isUpdate"
          >Update</vs-button
        >
        <a class="text-xs flex items-center underline mid-blue" @click="closeTillConnectorModal">Cancel</a>
      </vs-col>
    </vs-popup>
    <!--product config popup starts-->
    <vs-popup
      id="holamundo"
      class="config-popup paymentSummaryPopup"
      :title="`${activePlan.productName} configuration`"
      :active.sync="popupActivo"
      @close="closePopup"
    >
      <div class="w-full" v-if="activePlan.productType == 'B2C_BNPL'">
        <vs-col vs-w="12">
          <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start gray">
            <div class="flex flex-col justify-center items-center">
              <img :src="update" alt="update" />
              <span class="dark-blue text-xs mt-2"> Every</span>
              <span class="dark-blue text-xs">{{ `${this.everyNumber} ${this.weekOrMonthOrDay}` }}</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="playCircle" alt="playcircle" /><span class="dark-blue text-xs mt-2">Starts on</span>
              <span class="dark-blue text-xs">acceptance date</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="stopCircle" alt="stopcircle" />
              <span class="dark-blue text-xs mt-2"> Ends after</span>
              <span class="dark-blue text-xs">{{ `${this.activePlan.noOfPayments} payments` }}</span>
            </div>
          </vs-row>

          <vs-table :data="paymentSchedules" class="payment-schedule-table mt-3">
            <template slot="thead">
              <vs-th class="text-base"><span class="font-medium text-grey">Payment date</span></vs-th>
              <vs-th class="text-base"><span class="font-medium text-grey">Amount</span></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].value">
                  {{ formatScheduleDate(data[indextr].value) == formatScheduleDate(new Date()) ? `Today` : formatScheduleDate(data[indextr].value) }}
                </vs-td>

                <vs-td class="sec-row-payment" :data="data[indextr].label">
                  {{ data[indextr].label !== "0.00" ? `$ ${data[indextr].label}` : "-" }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <div class="vs-table--content">
            <vs-row class="flex justify-between">
              <span class="text-lg dark-blue">Customer pays</span>
              <span class="text-lg amount">{{this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
            </vs-row>
            <vs-row class="mt-4">
              <span class="text-lg dark-blue">Merchant receives</span>
              <span class="text-lg amount">{{this.merchantAmount? `$ ${addZeroes(this.merchantAmount)}` : "-"}}</span>
            </vs-row>
          </div>
        </vs-col>
      </div>

      <div class="w-full" v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(activePlan.productType)">
        <vs-col vs-w="12">
          <div class="gray mb-5 items-center flex">
            <div><img :src="calenderImage" alt="calenderImage" /></div><p class="dark-blue text-base font-normal ml-4">Proceeds will be deducted on the {{ activePlan.initialPeriod }} days from the acceptance date
            </p>
          </div>
          <div class="b2c">
          <vs-row class="flex justify-between">
            <span class="text-lg dark-blue">Customer pays</span>
            <span class="text-lg amount">{{this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-"}}</span>
          </vs-row>
          <vs-row class="mt-4">
            <span class="text-lg dark-blue">Merchant receives</span>
            <span class="text-lg amount">{{this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-"}}</span>
          </vs-row>
          </div>
        </vs-col>
      </div>
    </vs-popup>
    <!-- popup ends -->

    <pop-up :errorsList="errors" form-name="UserForm"></pop-up>

    <div class="flex flex-wrap justify-end mt-5 items-center">
      <vs-button v-round :disabled="isRequestSent" size="large" @click="prepareSubmit('save')">Save & Exit</vs-button>
      <vs-button v-if="activeMerchant || !partnerId" v-round :disabled="isRequestSent" size="large" class="lg:mx-8 md:mx-4 sm:mx-2 mx-2" @click="prepareSubmit('activate')">Activate Merchant</vs-button>
      <a class="mx-2 underline" v-round size="large" @click="$router.go(-1)">Cancel</a>
    </div>
  </div>
</template>
<script>

import { mapActions } from "vuex";
import { DEFAULT_PAYMENT_EXPIRY } from "./constant";
import { Validator } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mask } from "vue-the-mask";
import { PlusIcon, XIcon } from "vue-feather-icons";
import MultiSelectExtended from "../../../components/MultiSelectExtended";
import BankCard from "../../../components/BankCard";
import BankDetailsCard from "../../../components/BankDetailsCard";
import "sweetalert2/src/sweetalert2.scss";
import _ from "lodash";
import { Money } from "v-money";
import moment from "moment";

import PopUp from "@/views/components/ValidationPopup.vue";

const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const calenderImage = require("@/assets/images/calender.png");
import S3ImageGenerator from "../../../components/S3ImageGenerator";

const dictionary = {
  en: {
    attributes: {
      flipUsername: "username",
      flipPassword: "password",
      companyName: "partner name",
      phoneNumber: "phone number",
      requestTypes: "request types",
      firstName: "first name",
      lastName: "last name",
      tillName: "till name",
      displayName: "display name",
      apiKey: "api key",
      sharedSecret: "shared secret",
      publicIntegration: "publicIngerationKey",
      maskedMobileNumber: "phone number",
    },
    messages: {
      regex: () => "Phone number should be 10 digits",
      digits:(bsb) => "The bsb field must be 6 digits"
    },

  },
};
Validator.localize(dictionary);
export default {
  components: {
    PlusIcon,
    XIcon,
    MultiSelectExtended,
    Money,
    BankCard,
    BankDetailsCard,
    PopUp,
    S3ImageGenerator,
  },
  directives: { mask },
  data() {
    return {
      bankPopupTitle: "",
      credentialsSent: false,
      currentTillId: "",
      accountStatus: "",
      tillConnectorDetailsOfBank: "",
      bankObjectId: "",
      activeMerchant: false,
      isUpdate: false,
      paynow: false,
      paylater: false,
      cardsList: [],
      selectedCards: [],
      allCardsSelected: false,
      paylaterProductsList: [],
      selectedProducts: [],
      allProductsSelected: false,
      cardOptions: "",
      addBankAccountPopupActive: false,
      configureTillConnectorPopupActive: false,
      bankAccountList: [],
      tillIndex: "",
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
      msf: "",
      surcharge: "",
      bankIndex: "",
      requestOptions: [],
      serverUrl: process.env.VUE_APP_API_URL,
      addBankPopupActive: false,
      money2: {precision: 2, suffix: " %"},
      tillConnector: {
        connectorName: "",
        apiKey: "",
        integrationKey: "",
        sharedSecret: "",
      },
      addTillPopupActive: false,
      accountName: "",
      accountNumber: "",
      bsb: "",
      displayName: "",
      isUsedForSettlement: false,
      isUsedForBilling: false,
      accountId: "",
      isRequestSent: false,
      tillDetails: [],
      planSelect: [],
      currentBank: "",
      planSelectCopy: [],
      partnerId: "",
      paymentExpiry: DEFAULT_PAYMENT_EXPIRY,
      companyName: "",
      isSelectAll: false,
      firstName: "",
      lastName: "",
      onboardingId: "",
      onboardingRequestId: "",
      onboardingAgreementDate: "",
      phoneNumber: "",
      maskedMobileNumber: "",
      bankAccounts: [],
      requestTypes: [],
      paymentPlans: [],
      userType: "admin",
      role: "partner",
      flipUsername: "",
      flipPassword: "",
      requestTypesOptions: ["one-off", "recurring"],
      tillName: "",
      publicIntegration: "",
      apiKey: "",
      sharedSecret: "",
      tillSet: {
        _id: "",
        tillName: "",
        displayName: "",
        apiKey: "",
        sharedSecret: "",
        publicIntegration: "",
        partnerId: this.$route.params && this.$route.params.id ? this.$route.params.id : "",
      },
      cardList: [],
      userAssignedCards: [],
      cardSet: {
        _id: "",
        title: "",
        icon: "",
        surcharge: "",
        card: "",
        partner: this.$route.params && this.$route.params.id ? this.$route.params.id : "",
      },
      payLater: false,
      abn: "",
      image: "",
      companyLogo: "",
      statusCallbackApiKey: "",
      amount: "1000",
      weekOrMonthOrDay: "",
      activePlan: "",
      everyNumber: "1",
      popupActivo: false,
      totalCustomerPayable: "",
      merchantAmount: "",
      paymentSchedules: [],
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      calenderImage: calenderImage,
      setupLinkData: {
        contactName:"",
        emailAddress:""
      },
      isUiSetupComplete: false,
      bankIdsInPRTs: [],
      isUsedInPRT: false,
      bankIdsInPRs: [],
      isUsedInPR: false,
      msfRates: [],
      contactDetails: [
        {
          name: "Account owner",
          email: "",
          phoneNumber: ""
        },
        {
          name: "Accounts department",
          email: "",
          phoneNumber: ""
        },
        {
          name: "Technical support",
          email: "",
          phoneNumber: ""
        }
      ],
      numberMask: [
        {
          mask: "#### ### ###"
        },
        {
          mask: "#### ### ###"
        },
        {
          mask: "#### ### ###"
        },
      ]
    };
  },
  computed: {
    paymentOptions() {
      return { payNow: this.paynow, payLater: this.paylater };
    },
    currentBankName() {
      const item = this.bankAccountList.find((el) => el.bankAccountId === this.accountId);
      return item ? "Configure Till connector - " + item.accountName : "Configure Till connector";
    },
    isOnboarding() {
      return this.onboardingId && this.onboardingId.length > 0;
    }
  },
  methods: {
    ...mapActions("partner", [
      "storePartner",
      "fetchPartnerDetailById",
      "updatePartnerById",
      "sendCredentialsToMerchant",
      "resendMerchantSetupLinkForUI"
    ]),
    ...mapActions("onboardingAdmin", ["calculateOnPayLater"]),
    ...mapActions("connector", ["deleteConnectorById"]),
    ...mapActions("card", ["fetchAllCards", "deletePartnerCard"]),
    ...mapActions("paymentPlans", ["fetchAllPlans"]),
    ...mapActions("setting", ["fetchSetting"]),

    selectAll() {
      this.planSelect = this.paymentPlans;
    },

    //* functions for plan configuration popup start here
    async openPlanConfigurationPopup(plan, index) {
      this.activePlan = plan;
      this.popupActivo = true;

      await this.calculateOnPayLater({ planId: plan._id, msf: this.selectedProducts[index].msf }).then((result) => {
        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        if (this.activePlan.productType == "B2C_BNPL") {
          this.payLaterSchedule = schedule.map((item) => {
            return {
              label: item.amount,
              value: new Date(item.date),
            };
          });
          this.paymentSchedules = this.payLaterSchedule;
          this.totalNumberOfPayments = this.payLaterSchedule.length;
        }

        this.totalCustomerPayable = total.amount;
        this.merchantAmount = disbursement.amount;
      });

      // for finding week month or day
      if (this.activePlan.paymentFrequency) {
        if (this.activePlan.paymentFrequency == "DAILY") {
          this.weekOrMonthOrDay = "day";
        }
        if (this.activePlan.paymentFrequency == "WEEKLY") {
          this.weekOrMonthOrDay = "week";
        }
        if (this.activePlan.paymentFrequency == "FORTNIGHTLY") {
          this.everyNumber = "2";
          this.weekOrMonthOrDay = "weeks";
        }
        if (this.activePlan.paymentFrequency == "MONTHLY") {
          this.weekOrMonthOrDay = "month";
        }
      }
    },

    checkValidPhoneNumber(value) {
      let checkNumber = this.contactDetails[value].phoneNumber;
      this.numberMask[value].mask = this.checkDynamicPhoneNumber(checkNumber);
    },

    checkDynamicPhoneNumber(checkNumber) {
      let mask = "#### ### ###";

      if (checkNumber && checkNumber.length > 0) {
        let numLength = checkNumber.length;
        let numberOne = "";
        let numberTwo = "";

        if (numLength >= 2) {
          numberOne = checkNumber.substring(0, 2);

          if (numberOne === "13") {
            numberTwo = checkNumber.split(" ").join("").substring(0, 4);
          }
        }

        if (["02", "03", "07", "08"].includes(numberOne)) {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (numberOne === "13") {
          if (parseInt(numberTwo) == 1300) {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }

      return mask;
    },

    moment(date) {
      return moment(date).format("D-MMM-YY");
    },

    formatScheduleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    getWeekDay(date) {
      return moment(date).format("dddd");
    },

    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    closePopup() {
      this.weekOrMonthOrDay = "";
      this.activePlan = {};
      this.paymentSchedules = [];
      this.totalCustomerPayable = "";
      this.merchantAmount = "";
    },

    async sendCredentials(id) {
      await this.sendCredentialsToMerchant(id).then((result) => {
        Swal.fire("", "The username and password has been successfully sent!", "success");
      })
      .catch((ex) => {});
    },

    async getAllCards() {
      await this.fetchAllCards().then((res) => {
        this.cardsList = res.data.data;
      });
    },

    closeCard(index) {
      if (index > -1) {
        this.selectedCards.splice(index, 1);
      }
    },

    selectAllCards() {
      this.allCardsSelected = !this.allCardsSelected;

      if (this.allCardsSelected) {
        this.selectedCards = [...this.cardsList];
      } else {
        this.selectedCards = [];
      }
    },

    checkSelected() {
      this.allCardsSelected = (this.selectedCards.length == this.cardsList.length);
    },

    async getAllPaylaterProducts() {
      await this.fetchAllPlans('?page=1&limit=1000&sort=desc').then((res) => {
        this.paylaterProductsList = res.data.data.docs;
      });
    },

    closeProduct(index) {
      if (index > -1) {
        this.selectedProducts.splice(index, 1);
      }
    },

    selectAllProducts() {
      this.allProductsSelected = !this.allProductsSelected;

      if (this.allProductsSelected) {
        this.selectedProducts = [...this.paylaterProductsList];
      } else {
        this.selectedProducts = [];
      }
    },

    checkSelectedProducts() {
      this.allProductsSelected = (this.selectedProducts.length == this.paylaterProductsList.length);
    },

    addBankAccountPopup() {
      this.bsb = "";
      this.errors.clear();
      this.bankPopupTitle = "Add bank account";
      this.accountName = "";
      this.accountNumber = "";
      this.displayName = "";
      (this.isUsedForSettlement = false),
        (this.isUsedForBilling = false),
        (this.isUpdate = false);
      this.addBankAccountPopupActive = true;
    },

    createUniqueId(character) {
      let firstBits = Math.floor(1000 + Math.random() * 9000);
      let secondBits = Math.floor(1000 + Math.random() * 9000);
      let uniqueId = `${character}-${firstBits}-${secondBits}`;
      return uniqueId;
    },

    getBankPayload(type = "create") {
      let bankAccountId = this.createUniqueId("A");

      let payload = {
        accountName: this.accountName,
        bsb: this.bsb.split("-").join(""),
        accountNumber: this.accountNumber,
        displayName: this.displayName,
        isUsedForSettlement: this.isUsedForSettlement,
        isUsedForBilling: this.isUsedForBilling,
        bankAccountId: bankAccountId,
      };

      if (type == "update") {
        payload["_id"] = this.bankObjectId;
        payload["tillConnectorDetails"] = this.tillConnectorDetailsOfBank;

        if (this.accountId) {
          payload["bankAccountId"] = this.accountId;
        }
      }

      return payload;
    },

    addBankAccount() {
      this.$validator.validate("addBank.*").then(async (result) => {
        if (result) {
          const payload = this.getBankPayload("create");

          this.bankAccountList.push(payload);
          this.addBankAccountPopupActive = false;
          this.clearBankFields();
          this.showMessage("Bank Account", "Bank Account has been added.", "success", "icon-check-circle");
          this.$vs.loading.close();
        }
      });
    },

    clearBankFields() {
      this.$nextTick(() => {
        this.accountName = "";
        this.bsb = "";
        this.accountNumber = "";
        this.displayName = "";
        this.isUsedForSettlement = false;
        this.isUsedForBilling = false;
        this.bankIndex = "";
        this.isUsedInPRT = false;
        this.isUsedInPR = false;
      });
    },

    updateBankAccount() {
      this.$validator.validate("addBank.*").then(async (result) => {
        if (result) {
          const payload = this.getBankPayload("update");

          // To update the bank list array
          const final_data = [ ...this.bankAccountList.slice(0, this.bankIndex), payload, ...this.bankAccountList.slice(this.bankIndex + 1)];
          this.bankAccountList = final_data;
          this.errors.clear();
          this.addBankAccountPopupActive = false;
          this.clearBankFields();
          this.showMessage("Bank Account", "Bank Account has been updated.", "success", "icon-check-circle");
          this.$vs.loading.close();
        }
      });
    },

    closeModal() {
      this.addBankAccountPopupActive = false;
      this.isUsedInPRT = false;
    },

    configureTillConnectorPopup(bank, index) {
      this.tillIndex = index;
      this.tillName = "";
      this.publicIntegration = "";
      this.apiKey = "";
      this.sharedSecret = "";
      this.flipUsername = "";
      this.flipPassword = "";
      this.isUpdate = false;
      this.configureTillConnectorPopupActive = true;
      this.$validator.reset({ scope: "addTill" });
    },

    configureTillConnector() {
      this.$validator.validate("addTill.*").then(async (result) => {
        if (result) {
          const tillConnectorData = {
            tillName: this.tillName,
            publicIntegration: this.publicIntegration,
            apiKey: this.apiKey,
            sharedSecret: this.sharedSecret,
            tillUsername: this.flipUsername,
            tillPassword: this.flipPassword,
          };

          const currentBank = {
            ...this.bankAccountList[this.tillIndex],
            tillConnectorDetails: tillConnectorData,
          };

          this.bankAccountList.splice(this.tillIndex, 1, currentBank);

          this.tillName = "";
          this.publicIntegration = "";
          this.apiKey = "";
          this.sharedSecret = "";
          this.flipUsername = "";
          this.flipPassword = "";
          this.$vs.notify({
            title: "Configure Till Connector",
            text: "Till connector has been added.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$vs.loading.close();
          this.configureTillConnectorPopupActive = false;
        }
      });
    },

    editTillConnectorPopup(bank, index) {
      this.tillIndex = index;
      this.configureTillConnectorPopupActive = true;
      this.isUpdate = true;
      let getTillDetailsByIndex = this.bankAccountList[index].tillConnectorDetails;
      this.currentTillId = getTillDetailsByIndex._id;
      this.tillName = getTillDetailsByIndex.tillName;
      this.publicIntegration = getTillDetailsByIndex.publicIntegration;
      this.apiKey = getTillDetailsByIndex.apiKey;
      this.sharedSecret = getTillDetailsByIndex.sharedSecret;
      this.flipUsername = getTillDetailsByIndex.tillUsername;
      this.flipPassword = getTillDetailsByIndex.tillPassword;
    },

    updateTillConnector() {
      this.$validator.validate("addTill.*").then(async (result) => {
        if (result) {
          const updatedTillData = {
            _id: this.currentTillId,
            tillName: this.tillName,
            publicIntegration: this.publicIntegration,
            apiKey: this.apiKey,
            sharedSecret: this.sharedSecret,
            tillUsername: this.flipUsername,
            tillPassword: this.flipPassword,
          };
          this.bankAccountList[this.tillIndex].tillConnectorDetails =
            updatedTillData;

          this.$vs.notify({
            title: "Configure Till Connector",
            text: "Till connector has been updated.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$vs.loading.close();
          this.configureTillConnectorPopupActive = false;
        }
      });
    },

    closeTillConnectorModal() {
      this.configureTillConnectorPopupActive = false;
    },

    handleEditPopup(bankData, index) {
      this.bankPopupTitle = "Edit bank account";
      this.accountId = bankData.bankAccountId;
      this.addBankAccountPopupActive = true;
      this.isUpdate = true;
      this.bankObjectId = bankData._id;
      this.accountName = bankData.accountName;
      this.accountNumber = bankData.accountNumber;
      this.bsb = bankData.bsb;
      this.displayName = bankData.displayName;
      this.isUsedForSettlement = bankData.isUsedForSettlement;
      this.isUsedForBilling = bankData.isUsedForBilling;
      this.tillConnectorDetailsOfBank = bankData.tillConnectorDetails;
      this.bankIndex = index;
      this.isUsedInPRT = this.bankIdsInPRTs.includes(bankData._id);
      this.isUsedInPR = this.bankIdsInPRs.includes(bankData._id);
    },

    deleteBank() {
      this.bankAccountList.splice(this.bankIndex, 1);
      this.closeModal();
    },


    gotoPlan(id) {
      this.$router.push({
        name: "pay-later-product",
        query: { id },
      });
    },

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.companyLogo = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },

    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },

    prepareSubmit(event) {
      let bankWithBilling = [];
      let bankWithSetttelment = [];
      this.bankAccountList.map((item) => {
        if (item.isUsedForSettlement) {
          bankWithSetttelment.push(item);
        }
        if (item.isUsedForBilling) {
          bankWithBilling.push(item);
        }
      });

      if (event == "activate") {
        if (bankWithBilling.length == 0 && bankWithSetttelment.length == 0) {
          return this.$vs.notify({
            color: "danger",
            title: "Bank",
            text: "At least one bank account should be settlement account and one bank account should be billing account",
          });
        }
        if (bankWithBilling.length == 0) {
          return this.$vs.notify({
            color: "danger",
            title: "Bank",
            text: "At least one bank account should be billing account",
          });
        }
        if (bankWithSetttelment.length == 0) {
          return this.$vs.notify({
            color: "danger",
            title: "Bank",
            text: "At least one bank account should be settlement account",
          });
        }

        // case for pay now only and pay now and pay later isUsedForSettlement
        if ((this.paynow && !this.paylater) || (this.paynow && this.paylater)) {

          if (!this.bankAccountList.some((el) => (el.tillConnectorDetails && el.isUsedForSettlement))) {
            return this.$vs.notify({
              color: "danger",
              title: "Bank",
              text: "At least one bank account with till is required",
            });
          }
        }
      }

      this.$vs.loading();
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let data = new FormData();
          if (!this.paynow && !this.paylater) {
            this.$vs.notify({
              color: "danger",
              title: "Payment Options",
              text: "At least one of paynow or paylater payment options is required",
            });
            this.$vs.loading.close();
            return;
          }

          if (this.paynow == true) {
            this.requestOptions = ["pay-now"];
          }

          if (this.paylater == true) {
            this.requestOptions = ["pay-later"];
          }

          if (this.paylater == true && this.paynow == true) {
            this.requestOptions = ["pay-now", "pay-later"];
          }

          data.append("requestOptions", JSON.stringify(this.requestOptions));

          if (this.partnerId && event == "save") {
            if (this.partnerId && this.accountStatus == "Active" && this.paynow == true ) {
              const bankList = this.bankAccountList.filter((item) => (!item.tillConnectorDetails && item.isUsedForSettlement));

              if (bankList.length > 0) {
                this.$vs.notify({
                  color: "danger",
                  title: "Bank",
                  text: "Settlement bank requires till configuration",
                });
                this.$vs.loading.close();
                return;
              }
            }
            data.append("accountStatus", this.accountStatus);
          } else if (event == "save") {
            data.append("accountStatus", "Inactive");
          } else if (event == "activate") {
            let a = this.bankAccountList.filter((item) => (!item.tillConnectorDetails && item.isUsedForSettlement));

            if (a.length > 0 && this.paynow == true) {
              this.$vs.notify({
                color: "danger",
                title: "Bank",
                text: "Settlement bank requires till configuration",
              });
              this.$vs.loading.close();
              return;
            }
            data.append("accountStatus", "Active");
          }

          data.append("companyName", this.companyName);
          data.append("accountName", this.companyName);
          data.append("onboardingId", this.onboardingId);
          data.append("bankAccountList", JSON.stringify(this.bankAccountList));
          data.append("phoneNumber", this.maskedMobileNumber);
          data.append("role", this.role);

          if (this.companyLogo) {
            data.append("companyLogo", this.companyLogo);
          }

          data.append("abn", this.abn);
          data.append("payLaterPlans", JSON.stringify(this.selectedProducts));

          let cards = [];

          if (!this.partnerId) {
            cards = this.selectedCards.map((element) => {
              return {
                card: element._id,
                title: element.title,
                icon: element.icon,
                msf: element.msf,
                surcharge: element.surcharge,
              };
            });
          }
          if (this.partnerId) {
            cards = this.selectedCards.map((element) => {
              if (!element.fromDB) {
                return {
                  card: element._id,
                  title: element.title,
                  icon: element.icon,
                  msf: element.msf,
                  surcharge: element.surcharge,
                };
              }
              if (element.fromDB) {
                element.surcharge = element.surcharge;
                return element;
              }
            });
          }

          data.append("payNowPaymentOptions", JSON.stringify(cards));
          data.append("bankAccounts", JSON.stringify(this.bankAccounts));
          data.append("userType", this.userType);
          data.append("firstName", this.firstName);
          data.append("paymentExpiry", this.paymentExpiry);
          data.append("lastName", this.lastName);
          data.append("connectorsData", JSON.stringify(this.tillDetails));
          data.append("assignedCards", JSON.stringify(this.userAssignedCards));
          data.append("setupLinkData",JSON.stringify(this.setupLinkData));
          data.append("event", event);
          data.append("contactDetails", JSON.stringify(this.contactDetails));

          let obj = {
            data: data,
            config: {
              header: {
                "Content-Type": "application/json",
              },
            },
          };
          if (!this.partnerId) {
            this.savePartner(obj);
          } else {
            this.updatePartner(obj);
          }
        } else {
          this.$vs.loading.close();
        }
      }).catch((ex) => {
        this.isRequestSent = false;
        this.$vs.loading.close();
      });
    },

    async savePartner(obj) {
      await this.storePartner(obj).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Success", "Merchant has been created successfully.", "success", "icon-check-circle");
        this.$router.push({ name: "partners-list" });
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },

    async updatePartner(obj) {
      obj.id = this.partnerId;
      await this.updatePartnerById(obj).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Success", "Merchant has been updated successfully.", "success", "icon-check-circle");
        this.$router.push({ name: "partners-list" });
      }).catch((ex) => {
        this.$vs.loading.close();
        let message = ex.data ? ex.data.message : "Something went wrong";
        this.showMessage("Error", message, "danger", "icon-times");
      });
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async getPartnerDetail(id) {
      await this.fetchPartnerDetailById(id).then(async (result) => {
        this.credentialsSent = result.data.data.credentialsSent;
        this.accountStatus = result.data.data.accountStatus;

        if (result.data.data.accountStatus == "Inactive") {
          this.activeMerchant = true;
        }

        this.isUiSetupComplete = result.data.data.isUiSetupComplete;

        if (result.data.data.setupLinkData && result.data.data.setupLinkData.contactName) {
          this.$nextTick(() => {
            this.setupLinkData = result.data.data.setupLinkData;
          });
        } else {
          if (result.data.data.onboardingRequest && result.data.data.onboardingRequest.contactName) {
            this.setupLinkData.contactName = result.data.data.onboardingRequest.contactName || '';
            this.setupLinkData.emailAddress = result.data.data.onboardingRequest.email || '';
          } else {
            this.setupLinkData.contactName = result.data.data.fullName || '';
            this.setupLinkData.emailAddress = result.data.data.email || '';
          }
        }

        if (result.data.data.requestOptions) {
          if (result.data.data.requestOptions.includes("pay-now")) {
            this.paynow = true;
          }

          if (result.data.data.requestOptions.includes("pay-later")) {
            this.paylater = true;
          }
        }

        if (result.data.data.partnercardoptions) {
          let cards = [...result.data.data.partnercardoptions];
          cards.map((element) => {
            for (let i = 0; i < this.cardsList.length; i++) {
              if (element.card == this.cardsList[i]._id) {
                this.cardsList[i].fromDB = true;
                this.cardsList[i]._id = element._id;
                this.cardsList[i].card = element.card;
                this.cardsList[i].partner = element.partner;
                this.cardsList[i].msf = parseFloat(element.msf);
                this.cardsList[i].surcharge = parseFloat(element.surcharge);
                this.selectedCards.push(this.cardsList[i]);
              }
            }
          });
        }

        if (result.data.data.payLaterPlans) {
          let paylaterPlan = [...result.data.data.payLaterPlans];

          paylaterPlan.map((element) => {
            for (let i = 0; i < this.paylaterProductsList.length; i++) {
              if (element.customPlanId == this.paylaterProductsList[i].customPlanId) {
                this.paylaterProductsList[i].displayName = element.displayName;
                this.paylaterProductsList[i].msf = parseFloat(element.msf);
                this.selectedProducts.push(this.paylaterProductsList[i]);
              }
            }
          });
        }

        if (result.data.data.paymentRequestTemplates) {
          this.bankIdsInPRTs = result.data.data.paymentRequestTemplates.filter(item => item.deleted == false && item.linkedAccount).map(item => item.linkedAccount.bankId);
          this.bankIdsInPRTs = [...new Set(this.bankIdsInPRTs)];
        }

        if (result.data.data.paymentRequests) {
          this.bankIdsInPRs = result.data.data.paymentRequests.filter(item => (item.paymentStatus != "Completed" && (item.paymentPage || item.bankId))).map(item => item.bankId || item.paymentPage.bankId);
          this.bankIdsInPRs = [...new Set(this.bankIdsInPRs)];
        }

        if (result.data.data.banks) {
          this.bankAccountList = result.data.data.banks;
        }

        if (result.data.data.contactDetails.length > 0) {
          this.contactDetails = result.data.data.contactDetails;
        }
        // removing redundant id to make it similar to orginal plan
        this.planSelect = result.data.data.payLaterPlans.map((plan) => {
          return _.omit(plan, ["_id"]);
        });

        this.companyName = result.data.data.companyName;

        if (result.data.data.abn) {
          this.abn = result.data.data.abn;
        }

        if (result.data.data.onboardingId) {
          this.onboardingId = result.data.data.onboardingId;
        }

        if (result.data.data.onboarding) {
          this.onboardingRequestId = result.data.data.onboarding.onboardingRequestId;
          this.onboardingAgreementDate = result.data.data.onboarding.submittedDate;
        }

        if (result.data.data.companyLogo) {
          this.image = `${this.serverUrl}uploads/${result.data.data.companyLogo}`;
        }

        if (result.data.data.firstName) {
          this.firstName = result.data.data.firstName;
        }

        // removing redundant id to make it similar to orginal plan
        this.planSelect = result.data.data.payLaterPlans.map((plan) => {
          return _.omit(plan, ["_id"]);
        });

        if (result.data.data.lastName) {
          this.lastName = result.data.data.lastName;
        }

        if (result.data.data.phoneNumber) {
          this.phoneNumber = result.data.data.phoneNumber;
          this.maskedMobileNumber = this.phoneNumber;
        }

        if (result.data.data.paymentExpiry) {
          this.paymentExpiry = result.data.data.paymentExpiry || DEFAULT_PAYMENT_EXPIRY;
        }

        if (result.data.data.requestTypes) {
          this.requestTypes = result.data.data.requestTypes;
        }

        this.payLater = result.data.data.payLater ? result.data.data.payLater : false;

        if (result.data.data.connectors && result.data.data.connectors.length > 0) {
          let connectorData = result.data.data.connectors.map((item) => {
            return {
              _id: item._id,
              partnerId: item.partnerId,
              apiKey: item.apiKey,
              tillName: item.tillName,
              displayName: item.displayName,
              sharedSecret: item.sharedSecret,
              publicIntegration: item.publicIntegration,
            };
          });

          this.tillDetails = JSON.parse(JSON.stringify(connectorData));
        }
      })
      .catch((ex) => {});
    },

    addConnector() {
      this.tillDetails.push(JSON.parse(JSON.stringify(this.tillSet)));
    },

    addCard() {
      this.userAssignedCards.push(JSON.parse(JSON.stringify(this.cardSet)));
    },

    async chooseCardType(event, cardSlot) {
      let cardDetails = this.cardList.find(function (card) {
        return card._id === event;
      });

      cardSlot.title = cardDetails.title;
      cardSlot.icon = cardDetails.icon;
      cardSlot.card = event;
    },

    prepareDelete(detail, key) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "The connector will be permanently deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          self.deleteConnector(detail, key);
        }
      });
    },

    deleteConnector(detail, key) {
      if (detail._id) {
        this.deleteFromModel(detail._id);
      }
      this.tillDetails.splice(key, 1);
    },

    async deleteFromModel(id) {
      let status = await this.deleteConnectorById(id);

      if (status) {
        this.showMessage("Success", "Connector has been removed.", "success", "icon-check-circle");
      }
    },

    prepareCardDelete(detail, key) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "The card will be permanently deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          self.deleteCard(detail, key);
        }
      });
    },

    deleteCard(detail, key) {
      if (detail._id) {
        this.deleteCardFromModel(detail._id);
      }
      this.userAssignedCards.splice(key, 1);
    },

    removePlanCard(index) {
      this.planSelect.splice(index, 1);
    },

    async deleteCardFromModel(id) {
      const status = await this.deletePartnerCard(id);

      if (status) {
        this.showMessage("Success", "Card has been removed.", "success", "icon-check-circle");
      }
    },

    goToOnboarding(){
      const url = `${process.env.VUE_APP_BASE_URL}onboarding-review/${this.onboardingRequestId}`
      window.open(url, '_blank')
    },

    formatDate(date) {
      if (!date) {
        return date;
      }

      return moment(date).format("DD MMM YYYY");
    },

    async resendMerchantSetupLink(){
      const data = { partnerId :  this.partnerId };
      data.setupLinkData = this.setupLinkData;

      await this.resendMerchantSetupLinkForUI(data).then((response)=>{
        this.$vs.notify({
          color: "success",
          title: "Re-sent",
          text: "Merchant setup re-sent link send successfully.",
        });
      }).catch((ex)=>{
        console.log("err", ex);
      });
    },

    async getMsfSetting() {
      await this.fetchSetting("surcharge").then((res) => {
        this.msfRates = res.data.data.map((element) => {
          return {
            ...element,
            displayMsf: element.msf + " %"
          };
        });
      }).catch((ex) => {
        this.$vs.notify({
          title: "Error",
          text: "Error fetching setting",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    },

    checkMsf(index) {
      const objIndex = this.msfRates.findIndex((obj) => obj.msf == this.selectedCards[index].msf);

      if (objIndex < 0) {
        this.errors.add({
          "field" : `msf${index}`,
          "msg": "Please select valid msf from the list"
        });
      } else {
        if (this.errors.has(`msf${index}`)) {
          this.errors.remove(`msf${index}`);
        }
      }

      if (this.selectedCards[index].surcharge > this.selectedCards[index].msf) {
        this.errors.add({
          "field": `surcharge${index}`,
          "msg": `The surcharge field must be ${this.selectedCards[index].msf} or less`
        });
      } else {
        if (this.errors.has(`surcharge${index}`)) {
          this.errors.remove(`surcharge${index}`);
        }
      }
    },

    assignDefaultMsf(index) {
      const objIndex = this.msfRates.findIndex((obj) => obj.msf == this.selectedCards[index].msf);

      if (objIndex < 0) {
        this.selectedCards[index].msf = "1.50";
        this.selectedCards[index].surcharge = "1.50";
      }
    },

    handleBsb() {
      if (this.errors.has("addBank.bsb")) {
        this.errors.remove("addBank.bsb");
      }

      if (!this.bsb) {
        this.errors.add({
          field: "bsb",
          scope: "addBank",
          msg: "The bsb field is required",
        });
        return;
      }

      let validBsb = false;
      const bsbData = this.bsb.split("-");

      if (bsbData.length == 2 && bsbData[1].length == 3) {
        validBsb = true;
      }

      if (!validBsb) {
        this.errors.add({
          field: "bsb",
          scope: "addBank",
          msg: "The bsb field must be 6 digits",
        });
      }
    },
  },

  watch: {
    name(value) {
      this.tillName = value;
    },

    selectedCards(val) {
      if (this.$refs.payNowPaymentMethods) {
        this.$refs.payNowPaymentMethods.maxHeight = "none !important";
      }
      this.assignDefaultMsf(this.selectedCards.length - 1);
    },

    selectedProducts(val) {
      if (this.$refs.paylaterProducts) {
        this.$refs.paylaterProducts.maxHeight = "none !important";
      }
    },

    paymentOptions(val) {
      if (this.$refs.bankCollapse) {
        this.$refs.bankCollapse.maxHeight = "none !important";
      }
    },

    bankAccountList(val) {
      if (this.$refs.bankCollapse) {
        this.$refs.bankCollapse.maxHeight = "none !important";
      }
    },
  },

  async created() {
    this.getMsfSetting();
    this.getAllCards();
    await this.getAllPaylaterProducts();

    if (this.$route.params.id) {
      this.partnerId = this.$route.params.id;
      this.getPartnerDetail(this.$route.params.id);
    }

    Validator.extend("surchargeField", {
      getMessage: (field) =>
        parseInt(this.activePlan.minimumAmount) > 0 &&
        (this.amount < parseInt(this.activePlan.minimumAmount) ||
          this.amount == parseInt(this.activePlan.minimumAmount))
          ? "Amount must be above $" + this.activePlan.minimumAmount
          : parseInt(this.activePlan.maximumAmount) > 0 &&
            (this.amount > parseInt(this.activePlan.maximumAmount) ||
              this.amount == parseInt(this.activePlan.maximumAmount))
          ? " Amount must be below $" + this.activePlan.maximumAmount
          : "",
      validate: (value) =>
        value < parseInt(this.activePlan.maximumAmount) &&
        value > parseInt(this.activePlan.minimumAmount),
    });
    Validator.extend("requiredAmount", {
      getMessage: (field) => "Amount field is required",
      validate: (value) => !!value,
    });
    this.$validator.extend("phoneFormat", {
      getMessage: () => "The phone number format is invalid.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null,
    });
  },

  async beforeMount() {
    await this.fetchAllCards().then((res) => {
      this.cardList = res.data.data;
    }).catch((ex) => { });
  },
};
</script>